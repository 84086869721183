import React from 'react'
import Sale from './Sale'
import './ListSales.css'

function ListSales() {
  return (
    <div className='container--sales'>
      <Sale/>
      <Sale/>
      <Sale/>
      <Sale/>
      <Sale/>
      <Sale/>
      <Sale/>
      <Sale/>
    </div>
  )
}

export default ListSales