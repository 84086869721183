import Form from './Form'
import './App.css'

function App() {
  return (
    <div className='main'>
      <Form/>
    </div>
  );
}

export default App;
